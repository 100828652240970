import { useState } from "react";
// import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router";
import "./style.css";
import Loader from "../loader/Loader";
export const PreQuestion = () => {
  const navigate = useNavigate()
  var jsonDataArray = [
    {
      field: "gender",
      question: "What Is Your Gender?",
      answer: ["Male", "Female", "Other"],
    },

    {
      field: "age",

      question: "What Is Your Age?",
      answer: ["Under-25", "26-35", "36-45", "45-55", "56+"],
    },
    {
      field: "body_type",

      question: "What Is Your Body type?",

      answer: ["Average", "Slim", "Athletic", "Overweight", "Obese"],
    },
    {
      field: "active_level",

      question: "What Is Your Activity Level Like?",

      answer: [
        "Low/Sedentary (0 or less than 1 workout per week)",
        "Moderate (2 workouts per week)",
        "High (3-4 workouts per week)",
        "Very High (5+ workouts per week)",
      ],
    },
    {
      field: "sleep_hours",

      question: "On Average, How Many Hours Of Sleep Per Night Do You Achieve?",

      answer: ["Less than 4", "5-6", "7-8", "9+"],
    },
    {
      field: "water_drink",

      question: "How Much Water Do You Drink?",

      answer: [
        "Less than 4",
        "Around 6 glasses",
        "Around 8 glasses",
        "10+ glasses",
      ],
    },
    {
      field: "diet",

      question: "How Does Your Diet Typically Look On Most Days?",

      answer: [
        "Mostly Real Food: Fruits, Vegetables, Meats, Nuts",

        "Mostly Processed Food: Pre-Packaged Meals  Big Box Breakfast Cereals     Biscuits  Sodas, Candy",

        "A Mix Of Both Of The Above",
      ],
    },

    {
      field: "alcohol",

      question: "Alcohol",

      answer: [
        "None at all",

        "Less than 3 drinks per week",

        "5+ drinks per week",
        "10+ drinks per week",
      ],
    },
    {
      field: "caffeine",

      question: "Caffeine:",

      answer: [
        "None at all",

        "1 cup of coffee per day (or equivalent as an energy drink, matcha tea or other)",

        "2 cups per day",
        "3+ cups per day",
      ],
    },
    {
      field: "prescription_drugs",

      question:
        "Do You Use Prescription Drugs That May Affect Hormones Or Mood (Either directly e.g. SSRI’s for depression, or indirectly e.g. Hormonal and mood changes from birth control pills or other contraceptives)      ",

      answer: ["Yes", " No"],
    },
    {
      field: "stress_level",

      question: "  What Are Your Stress Levels Like?",

      answer: [" Low", " Moderate", "High", " Extreme"],
    },
    {
      field: "mindfulness_activities",

      question:
        " Do You Practice Mindfulness Activities? E.g. meditation or controlled breathing",

      answer: ["Yes", "No"],
    },
    {
      field: "profession_feel",

      question: " How Do You Feel About Your Work/Job/Profession?",

      answer: [
        "Happy and Satisfied",
        "Average and Indifferent",
        "Sad and Frustrated",
      ],
    },
    {
      field: "home_life",

      question: " How Do You Feel About Your Home Life?",

      answer: ["Happy", "Neutral", "Sad"],
    },
    {
      field: "social_life",

      question: " How Do You Feel About Your Social Life?",

      answer: ["Happy", "Neutral", "Sad"],
    },
    {
      field: "love_life",

      question: "How Do You Feel About Your Love Life?",

      answer: ["Happy", "Neutral", "Sad"],
    },
    {
      field: "family_condition",

      question: "How Well Do You Get On With Your Family?",

      answer: [" Very Well", "Just Ok", " Badly"],
    },
    {
      field: "social_media",

      question: "How Frequently Do You Use Social Media?",

      answer: [
        "  Not At All",
        " A Few Times Per Week Or Less",
        " A Few Times Per Day",
        " Constantly: I’m Hopelessly Addicted",
      ],
    },
    {
      field: "video_game",

      question: "Do You Play Video Games?",

      answer: [
        "   I Don’t Play Them",

        "  I Play Them Sometimes",
        " I Play Them Daily",
      ],
    },
  ];
  

  console.log(jsonDataArray.length);
  const [current, setCurrent] = useState(-1);
  const [change, setChange] = useState(false);
  const [sqlData, setSqlData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const slidePage = document.querySelector(".slide-page");
  const submitBtn = document.querySelector(".submit");
  const pages = document.querySelectorAll(".page");

  const stepsNumber = pages.length;
  document.documentElement.style.setProperty("--stepNumber", stepsNumber);

  function okok(event, e, s) {
    console.log(jsonDataArray.length);
    console.log(s);

    const question = jsonDataArray[s].question;
    const field = jsonDataArray[s].field;

    const answer = jsonDataArray[s].answer[e];
    let sql = { ...sqlData, [field]: answer };
    console.log(sql);
    setSqlData(sql);
    nextPage();
  }
  function storeValueAndNext(category, value) {
    let aa = { [category]: value };
    setChange(true);

    console.log({ ...aa });
    setSqlData({ ...aa });

    setCurrent(0);
  }
  async function postData() {
    // Default options are marked with *
   setIsLoading(true)
   
    await fetch("https://liv180-backend.onrender.com/api/create", {
      method: "POST",
      headers: {
        Accept: "application.json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(sqlData),
      cache: "default",
    }).then((res) => {
      setIsLoading(false)
    navigate("/chatbot")

    });
    // parses JSON response into native JavaScript objects
  }

  function nextPage(e) {
    // slidePage.style.marginLeft = `-${(100 / stepsNumber) * current}%`;
    setCurrent(current + 1);
  }

  return (
    <>
      <header class="w-full">
        <nav class="w-full flex justify-center items-center bg-white shadow-sm border border-gray-300 py-5 mb-5">
          <img
            src="https://liv180.uxdlabtech.com/wp-content/uploads/2023/09/Mask-gxzzxroup-1.png"
            alt=""
            />
        </nav>
      </header>

      <div class="container">
            {isLoading && <Loader />}
        <div class="w-full flex justify-center items-center flex-col">
          <div
            class="flex justify-center items-center flex-col"
            style={{ width: "70%" }}
          >
            <div class="text-4xl pb-10">
              Help us match you to the
              <span class="text-[#b36031]">right therapist</span>
            </div>
            <p class="w-full leading-5 text-center text-sm font-normal text-gray-600 lg:px-36 md:px-18 sm:px-8 px-4 xl:px-36 2xl:px-52">
              Please fill out this short questionnaire to provide some
              background information about you and the issues you'd like to deal
              with in therapy. It would help us match you with the most suitable
              therapist for you. Your answers will also give this therapist a
              good starting point in getting to know you.
            </p>
          </div>
          <div class="form-outer mt-8 pb-8">
            <div class="page slide-page overflow-hidden">
              {current < 0 && (
                <>
                  <div
                    onClick={() => storeValueAndNext("category", "Individual")}
                  >
                    <h3 class="xl:text-xl xl:mt-5 xl:pb-5 xl:text-start md:text-start lg:text-xl lg:mt-5 pb-5 sm:pb-5 md:pb-5 lg:pb-5 lg:text-start">
                      What type of therapy are you looking for?
                    </h3>
                    <div class="w-full rounded-xl py-8 cursor-pointer flex-col lg:items-start xl:items-star md:items-start sm:items-end items-end border flex image-add card-main bg-[#B36031] hover:border hover:border-[#996a4d]">
                      <h3 class="text-2xl px-8 lg:text-white md:text-black sm:text-black text-black xl:text-white text-custom-css">
                        Individual
                      </h3>
                      <span class="text-base px-8 lg:text-white md:text-black sm:text-black text-black xl:text-white font-medium text-custom-css">
                        Form myself
                        <i class="bi bi-arrow-right-circle text-sm icon-arrow"></i>
                      </span>
                    </div>
                  </div>
                  <div onClick={() => storeValueAndNext("category", "Couple")}>
                    <h3 class="text-xl mt-5 pb-5 mr-32"></h3>
                    <div class="w-full rounded-xl cursor-pointer py-8 flex-col lg:items-start xl:items-star md:items-start sm:items-end items-end border flex image-add2 bg-[#CA7E52] hover:border hover:border-[#895839]">
                      <h3 class="text-2xl px-8 lg:text-white md:text-black sm:text-black text-black xl:text-white">
                        Couple
                      </h3>
                      <span class="text-base px-8 lg:text-white md:text-black sm:text-black text-black xl:text-white font-medium">
                        Form myself
                        <i class="bi bi-arrow-right-circle text-sm icon-arrow"></i>
                      </span>
                    </div>
                  </div>
                  <div
                    onClick={() => storeValueAndNext("category", "Teen")}
                    class="pb-5"
                  >
                    <h3 class="text-xl mt-5 pb-5 mr-32"></h3>
                    <div class="w-full rounded-xl py-8 cursor-pointer flex-col lg:items-start xl:items-star md:items-start sm:items-end items-end border flex image-add3 bg-[#B36031] hover:border hover:border-[#996a4d]">
                      <h3 class="text-2xl px-8 lg:text-white md:text-black sm:text-black text-black xl:text-white">
                        Teen
                      </h3>
                      <span class="text-base px-8 lg:text-white md:text-black sm:text-black text-black xl:text-white font-medium">
                        Form myself
                        <i class="bi bi-arrow-right-circle text-sm icon-arrow"></i>
                      </span>
                    </div>
                  </div>
                </>
              )}
              {change && current < 19 && (
                <>
                  <div class="page">
                    {console.log(jsonDataArray[current], "test")}
                    <h3 class=" xl:text-xl lg:text-xl md:text-lg sm:text-lg text-base  mt-5 pb-5 mr-32 flex  lg:px-0 md:px-5 sm:px-0 px-0 xl:px-0 w-full add-class">
                      {" "}
                      {jsonDataArray[current].question}{" "}
                    </h3>
                    <div class=" py-8 flex-col items-start flex  card-main ml-[0px]  gap-4 h-auto">
                      {jsonDataArray[current].answer.map((option, res) => (
                        <button
                          onClick={(e) => okok(e, res, current)}
                          class="bg-[#de793e] text-sm sm:text-sm md:text-base lg:text-base xl:text-base w-full text-white flex justify-start text-start py-3 px-3 rounded-2xl hover:bg-[#B36031]"
                        >
                          {option}
                        </button>
                      ))}
                    </div>
                  </div>
                </>
              )}

              {current >= 19 && (
                <>
                  <div class="gap-4 page flex-col flex justify-center items-center">
                    <img
                      src="https://liv180.uxdlabtech.com/wp-content/uploads/2023/09/Completed-amico.png"
                      alt=""
                      class="w-44"
                    />
                    <h1 class="text-base sm:text-base md:text-lg lg:text-2xl xl:text-2xl 2xl:text-2xl  font-semibold   ">
                      Your Response Has Been Submitted Successfully{" "}
                    </h1>
                    <button
                      onClick={() => postData()}
                      class="bg-[#B36031] py-2 px-10 rounded-3xl text-white"
                    >
                      Home
                    </button>
                  </div>
                </>
              )}

              <div
                id="completion-line"
                class="w-full flex xl:flex-row md:flex-col items-center sm:flex-col flex-col lg:flex-row gap-5 rounded-xl py-6 px-8 border card-main bg-[#CA7E52] text-white"
              >
                <img
                  src="https://liv180.uxdlabtech.com/wp-content/uploads/2023/09/info-green-1-1.png"
                  alt=""
                  class="w-12"
                />
                <p class="text-base xl:text-start lg:text-start md:text-start sm:text-center text-center">
                  Step completed! Let's continue to find the best therapist for
                  you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer class="bg-white">
        <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
          <div class="md:flex md:justify-around">
            <div class="mb-6 md:mb-0 flex flex-col">
              <a
                href="#"
                class="flex items-center pb-9 sm:pb-9 md:pb-12 lg:pb-24 xl:pb-24 2xl:pb-28"
              >
                <img
                  src="https://liv180.uxdlabtech.com/wp-content/uploads/2023/09/Mask-gxzzxroup-1.png"
                  class="h-8 mr-3"
                  alt="liv180"
                />
              </a>

              <button class="bg-black text-white py-5 rounded-[52px] px-6 xl:w-full lg:w-full md:w-full sm:w-48 w-48">
                Get The Free app
              </button>
            </div>
            <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3 lg:gap-32 xl:gap-32">
              <div>
                <h2 class="mb-6 text-lg font-semibold text-gray-500 uppercase dark:text-white">
                  Discover
                </h2>
                <ul class="text-black dark:text-gray-400 font-medium text-base underline">
                  <li class="mb-4 flex flex-col">
                    <a href="#" class="hover:underline">
                      Events
                    </a>
                    <a href="#" class="hover:underline">
                      Community
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h2 class="mb-6 text-lg font-semibold text-gray-500 uppercase dark:text-white">
                  Businesses
                </h2>
                <ul class="text-black font-medium text-base underline">
                  <li class="mb-4 flex flex-col">
                    <a href="#" class="hover:underline">
                      Add a event
                    </a>
                    <a href="#" class="hover:underline">
                      Add an item to
                    </a>
                    <a href="#" class="hover:underline">
                      marketplace
                    </a>
                    <a href="#" class="hover:underline">
                      Become a sponsor
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h2 class="mb-6 text-lg font-semibold text-gray-500 uppercase dark:text-white">
                  LIV180
                </h2>
                <ul class="text-black dark:text-gray-400 font-medium text-base">
                  <li class="mb-4 flex flex-col underline">
                    <a href="#" class="hover:underline">
                      Shop
                    </a>
                    <a href="#" class="hover:underline">
                      About
                    </a>
                    <a href="#" class="hover:underline">
                      Contact us{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <div class="sm:flex sm:items-center sm:justify-between lg:justify-center xl:justify-center gap-3">
            <span class="text-base text-gray-500 sm:text-center dark:text-gray-400">
              © 2023
              <a href="#" class="hover:underline">
                {" "}
                LIV180™ -
              </a>
              All Rights Reserved.
            </span>
            <a href="#" class="text-[#b36031] text-base-">
              {" "}
              Privacy policy
            </a>
            |
            <a href="#" class="text-[#b36031] text-base">
              {" "}
              Disclaimer
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};
