import React from 'react';
import './Loader.css';

const Loader = () => {
 return (
    <div className="loading-screen">
       <div className='loader'>

       </div>
    </div>
 );
};

export default Loader;