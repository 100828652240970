import React from "react";
import { Routes, Route } from "react-router";
import {PreQuestion}  from "./Components/PreQuestion/preQuestion";
import { ChatBot } from "./Components/chatBot/chatbot";

export const Router = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<PreQuestion />} /> 
        <Route path="/chatBot" element={<ChatBot />} /> 

      </Routes>
    </>
  );
};
