import "./style.css"

export const ChatBot = () => {
  function watson() {
    window.watsonAssistantChatOptions = {
      integrationID: "1db9e528-7969-40f4-964a-efae98b490d5", // The ID of this integration.
      region: "us-south", // The region your integration is hosted in.
      serviceInstanceID: "de947396-6639-4723-a964-36a7a2fddef9", // The ID of your service instance.
      onLoad: function (instance) {
        instance.render();
      },
    };
    setTimeout(function () {
      const t = document.createElement("script");
      t.src =
        "https://web-chat.global.assistant.watson.appdomain.cloud/versions/" +
        (window.watsonAssistantChatOptions.clientVersion || "latest") +
        "/WatsonAssistantChatEntry.js";
      document.head.appendChild(t);
    });
  }
  return (
    <>
      <div  id="watson-chatbox" >
        <div className="chatboxStyle">
        {watson()}
        </div>
        </div>
    </>
  );
};
